import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import estores from '../../img/windowspvc/estores.webp';
import estores1 from '../../img/windowspvc/estores1.webp';
import estores2 from '../../img/windowspvc/estores2.webp';
import estores3 from '../../img/windowspvc/estores3.webp';
import estores4 from '../../img/windowspvc/estores4.webp';
import estores5 from '../../img/windowspvc/estores5.webp';
import estores6 from '../../img/windowspvc/estores6.webp';

export function Estores() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_r4ab6qd', 'template_0hr844j', form.current, 'user_w06n8dXFy9FVNFRTD6mju')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <div>
            <section className="pb-7 shadow-5">
                <div className="container">
                    <div className="row galleries">
                        <div className="col-lg-8 mb-6 mb-lg-0">
                            <div className="item custom-size-1">
                                <div className="card p-0 hover-zoom-in">
                                    <a href={estores} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores})` }}>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row no-gutters m-n1 pb-7">
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores1} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores1})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores2} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores2})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores3} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores3})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores4} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores4})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores5} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores5})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={estores6} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${estores6})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="primary-content bg-gray-01 pt-7 pb-13">
                <div className="container">
                    <div className="row">
                        <article className="col-lg-8">
                            <section className="pb-8 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-3">Estores</h4>
                                <p className="mb-2 lh-214">
                                    Entre outras áreas, a nossa empresa especializou-se na produção e na venda de estores – de PVC -,
                                    para janelas de todos os modelos e formatos, podendo a abertura e o fecho ser controlados
                                    manualmente ou, se preferir, através de um inovador interruptor elétrico ou mediante um comando à
                                    distância.
                                </p>
                                <p className="mb-2 lh-214">
                                    Faça a sua escolha. A nossa oferta inclui estores translúcidos, estores blackout, estores de caixa
                                    compacta em PVC, estores de lâminas térmicas, estores com rede mosquiteira (…), e estão também disponíveis
                                    em inúmeras cores, tamanhos e formatos, tendo a mais-valia de se adaptarem a qualquer casa ou edifício.
                                </p>
                                <p className="mb-2 lh-214">
                                    Feitos em materiais de eleição, como o PVC, os nossos artigos têm grande capacidade de
                                    resistência e conferem privacidade ao interior dos espaços, permitindo igualmente um controlo adequado
                                    da luminosidade, fazendo com que as divisões se mantenham frescas no verão e quentes no inverno.
                                </p>
                                <p className="mb-2 lh-214">

                                    Seja para reparar os seus estores antigos ou substitui-los por outros novos e mais modernos, na BS Caixilharia,
                                    encontra uma equipa profissional e especializada na reparação e instalação de estores de
                                    todos os modelos, dimensões e sistemas de movimentação. Contacte-nos, peça-nos um orçamento gratuito, e
                                    surpreenda-se com os nossos preços.
                                </p>
                            </section>

                            <section className="mt-2 pb-7 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-4">Vantagens</h4>
                                <ul className="list-unstyled mb-0 row no-gutters">
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Elevada versatilidade e funcionalidade (comando à distância ou interruptor elétrico)
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Privacidade e conforto máximos dos espaços interiores
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Excelente isolamento térmico e acústico
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Controlo da entrada da luminosidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Robustez e resistência a fatores climáticos (chuva, vento, calor, granizo, etc.)
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Possibilidade de incorporar redes mosquiteiras, sistemas anti-intrusão, anti-roubo e deteção de fumo
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Maior eficiência energética dos espaços
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Elevada durabilidade e fiabilidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Fáceis de limpar e lavar
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Design moderno e inovador
                                    </li>
                                </ul>
                            </section>

                        </article>
                        <aside className="col-lg-4 pl-xl-4 primary-sidebar sidebar-sticky" id="sidebar">
                            <div className="primary-sidebar-inner">
                                <div className="card mb-4">
                                    <div className="card-body px-6 py-4 text-center">
                                        <a href="agent-details-1.html" className="fs-16 lh-214 text-dark mb-0 font-weight-500 hover-primary">
                                            BS Caixilharia
                                        </a>
                                        <p className="mb-0">Comercial</p>
                                        <a href="mailto:bscaixilharia@gmail.com" className="text-body">bscaixilharia@gmail.com</a>
                                        <a href="tel:+351932727803" className="text-heading font-weight-600 d-block mb-4">
                                            (+351) 932 727 803
                                        </a>
                                        <form ref={form} onSubmit={sendEmailForm}>
                                            <div className="form-group mb-2">
                                                <label htmlFor="name" className="sr-only">Full Name</label>
                                                <input type="text" placeholder="Full Name" className="form-control form-control-lg border-0 shadow-none"
                                                    name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="email" className="sr-only">Email</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="email" placeholder="Your email"
                                                    name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="phone" className="sr-only">Phone</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="phone" placeholder="Your phone"
                                                    name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="message" className="sr-only">Message</label>
                                                <textarea className="form-control border-0 shadow-none" id="message" defaultValue={""}
                                                    name="message" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-lg btn-block shadow-none">Solicitar Contacto
                                            </button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>

    );
}