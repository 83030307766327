import { Link } from "react-router-dom";

type BreadcrumbContextType = {
    namePage: string;
}

export function Breadcrumb(props: BreadcrumbContextType) {
    return (
        <section className="pb-6 page-title shadow">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pt-6 pt-lg-2 pb-5 lh-15">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{props.namePage}</li>
                    </ol>
                </nav>
                <h1 className="fs-30 lh-16 mb-1 text-dark font-weight-600">{props.namePage}</h1>
            </div>
        </section>
    );
}