
import simon from '../../img/profilecomments/simon.webp';
import matheus from '../../img/profilecomments/matheus.webp';
import lidia from '../../img/profilecomments/lidia.webp';

export function CommentsGoogle() {
    return (
        <section className="py-lg-1 py-2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 pr-xl-17" data-animate="fadeInLeft">
                        <h2 className="text-heading lh-163">Por que as pessoas nos amam?</h2>
                        <span className="heading-divider" />
                        <p className="mb-6">Nos avalie no Google meu Negócio, pois a sua indicação fortalece o nosso trabalho!</p>
                        {/* <a href="https://g.page/r/CYLQE98MPpmhEAg/review" target="_blank" className="btn btn-lg text-secondary btn-accent rounded-lg mb-8 mb-lg-0">
                            Comentar no Google
                            <i className="far fa-long-arrow-right ml-1" />
                        </a> */}
                    </div>
                    <div className="col-lg-7" data-animate="fadeInRight">
                        <div className="slick-slider custom-vertical mx-0" data-slick-options="{&quot;slidesToShow&quot;: 1,&quot;vertical&quot;:true,&quot;verticalSwiping&quot;:true,&quot;centerMode&quot;:true,&quot;swipeToSlide&quot;:true,&quot;focusOnSelect&quot;:true,&quot;centerPadding&quot;:&quot;120px&quot;,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;dots&quot;:false,&quot;arrows&quot;:false}">
                            <div className="box px-sm-8">
                                <div className="card border-0 shadow-lg-3 px-3 pl-md-9 pr-md-9 pt-8 pb-7">
                                    <div className="card-body p-0">
                                        <h5 className="card-title fs-18 text-secondary mb-3 lh-17">Qualidade e Profissionalismo!</h5>
                                        <p className="card-text fs-18 lh-2 text-heading mb-5">
                                            Serviço muito profissional, com muita qualidade. Recomendo vivamente. Continuem o ótimo trabalho e sem dúvida que procurarei os vossos serviços novamente.
                                        </p>
                                        <div className="media align-items-center">
                                            <div className="rounded-circle mr-4 w-70px h-70">
                                                <img src={simon} alt="Simon Costa" />
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-17 lh-1 text-heading font-weight-600 mb-2">Simon Costa</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box px-sm-8">
                                <div className="card border-0 shadow-lg-3 px-3 pl-md-9 pr-md-9 pt-8 pb-7">
                                    <div className="card-body p-0">
                                        <h5 className="card-title fs-18 text-secondary mb-3 lh-17">Velocidade na solução.</h5>
                                        <p className="card-text fs-18 lh-2 text-heading mb-5">
                                            Depois de pesquisar durante muito tempo, me indicaram os serviços do Sr Ferreira, que prontamente me atendeu e resolveu meus problemas quase que de imediato.
                                        </p>
                                        <div className="media align-items-center">
                                            <div className="rounded-circle mr-4 w-70px h-70">
                                                <img src={lidia} alt="Lidia Moura" />
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-17 lh-1 text-heading font-weight-600 mb-2">Lidia Moura
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box px-sm-8">
                                <div className="card border-0 shadow-lg-3 px-3 pl-md-9 pr-md-9 pt-8 pb-7">
                                    <div className="card-body p-0">
                                        <h5 className="card-title fs-18 text-secondary mb-3 lh-17">Serviço de Qualidade!</h5>
                                        <p className="card-text fs-18 lh-2 text-heading mb-5">
                                            Serviço e atendimento de primeira! Foram super atenciosos e ágeis na instalação e nota 10 ao acabamento! Parabéns e muito obrigado por prestarem um serviço de qualidade!
                                        </p>
                                        <div className="media align-items-center">
                                            <div className="rounded-circle mr-4 w-70px h-70">
                                                <img src={matheus} alt="Matheus Aguiar" />
                                            </div>
                                            <div className="media-body">
                                                <p className="fs-17 lh-1 text-heading font-weight-600 mb-2">Matheus Aguiar
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}