import error404 from '../../img/page-404.webp';

export function Error404() {
    return (
        <section className="pt-9 pb-10">
            <div className="container">
                <div className="text-center mb-15">
                    <img src={error404} alt="Page 404" className="mb-5" />
                    <h1 className="fs-30 lh-16 text-dark font-weight-600 mb-5">Oops...! Esta página não foi encontrada</h1>
                </div>
            </div>
        </section>

    );
}