import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import aluminio1 from '../../img/windowspvc/aluminio1.webp';
import aluminio2 from '../../img/windowspvc/aluminio2.webp';
import aluminio3 from '../../img/windowspvc/aluminio3.webp';
import aluminio4 from '../../img/windowspvc/aluminio4.webp';

export function Aluminio() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_r4ab6qd', 'template_0hr844j', form.current, 'user_w06n8dXFy9FVNFRTD6mju')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <div>
            <section className="pb-7 shadow-5">
                <div className="container">
                    <div className="row galleries">
                        <div className="col-lg-8 mb-6 mb-lg-0">
                            <div className="item custom-size-1">
                                <div className="card p-0 hover-zoom-in">
                                    <a href={aluminio1} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${aluminio1})` }}>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row no-gutters m-n1 pb-7">
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={aluminio2} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${aluminio2})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={aluminio3} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${aluminio3})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={aluminio4} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${aluminio4})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="primary-content bg-gray-01 pt-7 pb-13">
                <div className="container">
                    <div className="row">
                        <article className="col-lg-8">
                            <section className="pb-8 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-3">Caixilharia Alumínio</h4>
                                <p className="mb-2 lh-214">
                                    A nossa empresa é perita na comercialização de portas de alumínio, para interior e exterior,
                                    nomeadamente da conceituada marca Schüco, as quais combinam vários designs com o mais alto grau de
                                    segurança, isolamento térmico, estanqueidade e durabilidade, que procura para a sua casa ou empresa.
                                </p>
                                <p className="mb-2 lh-214">
                                    De referir que o alumínio é um material de construção que dura muito tempo, sólido e altamente
                                    resistente às intempéries, ao frio e ao calor, e dificilmente apresentará sinais de oxidação
                                    (ferrugem), oferecendo níveis de isolamento térmico e acústico bastante eficazes e satisfatórios.
                                </p>
                                <p className="mb-2 lh-214">
                                    Leves e fáceis de abrir e de fechar, os equipamentos que vendemos e instalamos são também fáceis
                                    de limpar e de manter, estando disponíveis numa grande variedade de modelos e cores, podendo ser
                                    perfeitamente ajustados ao seu gosto e à arquitetura do edifício.
                                </p>
                                <p className="mb-2 lh-214">
                                    Encomende já as suas portas de alumínio à nossa empresa, e conte desde logo com uma equipa de
                                    profissionais atenta e disponível para lhe prestar todo o aconselhamento técnico sobre o modelo
                                    mais indicado para si. Temos as soluções mais económicas e seguras para a sua casa ou local de trabalho.
                                </p>
                            </section>

                            <section className="mt-2 pb-7 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-4">Vantagens</h4>
                                <ul className="list-unstyled mb-0 row no-gutters">
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Resistência e Durabilidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Têm máxima estabilidade e durabilidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Leve
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Fácil limpeza
                                    </li>
                                </ul>
                            </section>

                        </article>
                        <aside className="col-lg-4 pl-xl-4 primary-sidebar sidebar-sticky" id="sidebar">
                            <div className="primary-sidebar-inner">
                                <div className="card mb-4">
                                    <div className="card-body px-6 py-4 text-center">
                                        <a href="agent-details-1.html" className="fs-16 lh-214 text-dark mb-0 font-weight-500 hover-primary">
                                            BS Caixilharia
                                        </a>
                                        <p className="mb-0">Comercial</p>
                                        <a href="mailto:bscaixilharia@gmail.com" className="text-body">bscaixilharia@gmail.com</a>
                                        <a href="tel:+351932727803" className="text-heading font-weight-600 d-block mb-4">
                                            (+351) 932 727 803
                                        </a>
                                        <form ref={form} onSubmit={sendEmailForm}>
                                            <div className="form-group mb-2">
                                                <label htmlFor="name" className="sr-only">Full Name</label>
                                                <input type="text" placeholder="Full Name" className="form-control form-control-lg border-0 shadow-none"
                                                    name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="email" className="sr-only">Email</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="email" placeholder="Your email"
                                                    name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="phone" className="sr-only">Phone</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="phone" placeholder="Your phone"
                                                    name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="message" className="sr-only">Message</label>
                                                <textarea className="form-control border-0 shadow-none" id="message" defaultValue={""}
                                                    name="message" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-lg btn-block shadow-none">Solicitar Contacto
                                            </button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>

    );
}