import { Link } from 'react-router-dom';
import window2 from '../../img/windowspvc/window2.webp';

export function PropertyAmenities() {
    return (
        <section>
            <div className="container container-xxl">
                <div className="slick-slider" data-slick-options="{'slidesToShow': 1, 'autoplay':true,'dots':false,'arrows':false}">
                    <div className="bg-cover" style={{ backgroundImage: `url(${window2})` }} data-animate="zoomIn">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-12 col-md-8 py-11">
                                    <div className="bg-white rounded-lg position-relative z-index-2 pl-sm-13 pr-sm-10 py-sm-9 p-6">
                                        <h2 className="mt-2"><Link to="/windows-pvc" className="text-heading hover-primary">Janelas em PVC</Link></h2>
                                        <span className="heading-divider" />
                                        <p className="pt-2">Há inúmeras vantagens em adquirir janelas em PVC sejam elas de abrir, correr, basculantes
                                            , de batente, oscilobatente ou fixas.</p>
                                        <ul className="list-unstyled row no-gutters mb-0">
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                Garantem durabilidade
                                            </li>
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                Isolamento térmico e acústico
                                            </li>
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                Enquadramento perfeito e harmonioso no seu projeto
                                            </li>
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                São resistentes a todo o tipo de danos
                                            </li>
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                Maior eficiência energética
                                            </li>
                                            <li className="col-sm-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                                Fáceis de limpar
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}