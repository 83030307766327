import pattern5 from '../../img/pattern-05.webp';
import pattern6 from '../../img/pattern-06.webp';
import mission from '../../img/mission.webp';
import values from '../../img/values.webp';
import vision from '../../img/vision.webp';
import { Link } from 'react-router-dom';

export function AboutUs() {
    return (
        <section className="bg-patten-03 bg-gray-01 pb-13 p-6" style={{ backgroundImage: `url(${pattern5}), url(${pattern6})`, backgroundColor: "#f8f8f8", backgroundPosition: "left bottom,right top", backgroundRepeat: "no-repeat,no-repeat", backgroundSize: "auto,auto" }}>
            <div className="container">
                <div className="card border-0 mb-12">
                    <div className="card-body p-6 px-lg-14 py-lg-13">
                        <p className="letter-spacing-263 text-uppercase text-primary mb-6 font-weight-500 text-center">
                            Bem Vindos a BS CAIXILHARIA
                        </p>
                        <h2 className="text-heading mb-4 fs-22 fs-md-32 text-center lh-16 px-6">
                            A BS. Caixilharia vem oferencendo o que há de melhor em vidros temperados.
                            Estamos localizado no Porto e atendemos toda a região.
                        </h2>
                        <p className="text-center px-lg-11 fs-15 lh-17 mb-11">
                            Todos os materias usados em nossos serviços são de máxima qualidade,
                            trabalhamos com PVC e ferragem tudo de 1º linha, assim como nosso
                            atendimento. Preparamos nossos profissionais para prestar o melhor serviço
                            de análise, medição e instalação, garantindo a satisfação desde o primeiro
                            contato até a entraga do resultado final.
                        </p>
                        <p className="letter-spacing-263 text-uppercase mb-4 font-weight-500 text-center">Serviços</p>
                        <div className="d-flex flex-wrap justify-content-center">
                            <Link to="/windows-pvc" className="btn btn-lg bg-gray-01 text-body mr-4 mb-4 hover-primary">Janelas em PVC</Link>
                            <a href="#" className="btn btn-lg bg-gray-01 text-body mr-4 mb-4 hover-primary">Portas PVC</a>
                        </div>
                    </div>
                </div>
                <h2 className="text-dark lh-1625 text-center mb-2 fs-22 fs-md-32">Missão, Visão e Valores</h2>
                <div className="row mt-8">
                    <div className="col-md-4 mb-6 mb-lg-0">
                        <div className="card shadow-2 px-7 pb-6 pt-4 h-100 border-0">
                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                <span className="text-primary fs-90 lh-1">
                                    <img src={mission} alt="mission" style={{ width: "90px" }} />
                                </span>
                            </div>
                            <div className="card-body px-0 pt-6 pb-0 text-center">
                                <h4 className="card-title fs-18 lh-17 text-dark mb-2">Missão</h4>
                                <p className="card-text px-2">
                                    A BS Caixilharia tem como missão, o fornecimento e instalação de caixilharia
                                    em PVC com um elevado padrão de qualidade nos materiais e
                                    serviços prestados, de forma a corresponder e superar as expectativas
                                    dos nossos clientes, além de valorizar e instruir nossos funcionários para
                                    proporcionar o crescimento contínuo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-6 mb-lg-0">
                        <div className="card shadow-2 px-7 pb-6 pt-4 h-100 border-0">
                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                <span className="text-primary fs-90 lh-1">
                                    <img src={vision} alt="mission" style={{ width: "90px" }} />

                                </span>
                            </div>
                            <div className="card-body px-0 pt-6 pb-0 text-center">
                                <h4 className="card-title fs-18 lh-17 text-dark mb-2">Visão</h4>
                                <p className="card-text px-2">
                                    Nossa visão é ser reconhecida pelos nossos
                                    clientes e possíveis clientes por nosso
                                    compromisso, responsabilidade, competência, rigor,
                                    integridade, e qualidade de maneira a obter total
                                    satisfação.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-6 mb-lg-0">
                        <div className="card shadow-2 px-7 pb-6 pt-4 h-100 border-0">
                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                <span className="text-primary fs-90 lh-1">
                                    <img src={values} alt="mission" style={{ width: "90px" }} />

                                </span>
                            </div>
                            <div className="card-body px-0 pt-6 text-center pb-0">
                                <h4 className="card-title fs-18 lh-17 text-dark mb-2">Valores</h4>
                                <p className="card-text px-2">
                                    Buscamos ser uma empresa de referência no setor da Caixilharia, de
                                    forma a estar sempre à frente com soluções inovadoras.
                                    Assegurar a satisfação e incentivo de nossos funcionários a trabalhar
                                    sempre com o melhor procedimento. Estar sempre cientes da nossa responsabilidade
                                    técnica e social resultante da confiança depositada em nós, na busca de
                                    avançar com nossas metas e objetivos
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}