import { Link } from "react-router-dom";

export function Footer() {

    return (
        <footer className="bg-dark pt-10 pb-8 footer">
            <div className="container">
                <ul className="navbar-nav bg-dark flex-row flex-wrap justify-content-center mb-6 pb-1">
                    <li className="nav-item px-8">
                        <Link className="nav-link fs-13 font-weight-500 lh-2 text-white text-uppercase" to="/terms-conditions" id="mortgage-rates" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Terms & Conditions
                        </Link>
                    </li>
                    <li className="nav-item px-8">
                        <Link className="nav-link fs-13 font-weight-500 lh-2 text-white text-uppercase" to="/privacy-policy" id="mortgage-rates" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Privacy & Policy
                        </Link>
                    </li>
                </ul>

                <div className="text-center text-white fs-22 lh-2 font-weight-500 mxw-751 mb-4">
                    Tudo o que você precisa saber ao olhar para diferentes tipos de caixilharias à disposição do seu projeto.
                </div>
                <ul className="list-inline text-center mb-6">
                    <li className="list-inline-item mr-3 text-white opacity-7">
                        © 2024 BS Caixilharia. All Rights Reserved
                    </li>
                    <li className="list-inline-item divider mr-3" />
                    <li className="list-inline-item mr-3">
                        <a href="tel:+351932727803" className="text-white font-weight-bold fs-15">(+351) 932 727 803</a>
                    </li>
                    <li className="list-inline-item divider mr-3" />
                    <li className="list-inline-item">
                        <a href="mailto:bscaixilharia@gmail.com" className="text-white opacity-7 hover-white">bscaixilharia@gmail.com</a>
                    </li>
                </ul>
                <ul className="list-inline text-center mb-2">
                    <li className="list-inline-item mr-0">
                        <a href="https://www.facebook.com/BS-Caixilharia-101829489122936" target="_blank" className="text-white opacity-5 fs-18 px-4 hover-primary opacity-hover-10">
                            <i className="fab fa-facebook" />
                        </a>
                    </li>
                    <li className="list-inline-item mr-0">
                        <a href="https://www.instagram.com/bscaixilharia/" target="_blank" className="text-white opacity-5 fs-18 px-4 hover-primary opacity-hover-10">
                            <i className="fab fa-instagram" />
                        </a>
                    </li>
                    <li className="list-inline-item mr-0">
                        <a href="https://goo.gl/maps/kejuLZaFW9kn6QMw6" target="_blank" className="text-white opacity-5 fs-18 px-4 hover-primary opacity-hover-10">
                            <i className="fal fa-map-marker-alt"></i>
                        </a>
                    </li>
                    <li className="list-inline-item mr-0">
                        <a href="mailto:bscaixilharia@gmail.com" className="text-white opacity-5 fs-18 px-4 hover-primary opacity-hover-10">
                            <i className="fal fa-envelope"></i>
                        </a>
                    </li>
                    <li className="list-inline-item mr-0">
                        <a href="tel:+351223700621" className="text-white opacity-5 fs-18 px-4 hover-primary opacity-hover-10">
                            <i className="fal fa-phone"></i>
                        </a>
                    </li>
                </ul>
                <ul className="list-inline text-center">
                    <li className="list-inline-item text-white opacity-7">
                        Developer by <a href="https://www.instagram.com/itrocketpt/" target="_blank">IT Rocket</a>
                    </li>
                </ul>
            </div>
        </footer>

    );
}