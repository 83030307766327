import estores from '../../img/services/estores.webp';
import portaandjanelacaixilharia from '../../img/services/portaandjanelacaixilharia.webp';
import caixilhariaaluminioepvcmarquises from '../../img/services/caixilhariaaluminioepvcmarquises.webp';
import portasaluminios from '../../img/services/portasaluminios.webp';
import cortinasdevidro from '../../img/services/cortinasdevidro.webp';
import janelasaluminiopvc from '../../img/services/janelasaluminiopvc.webp';

export function Services() {
    return (
        <section className="pt-8 pb-13" data-animated-id={2}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="text-dark text-center mb-8 pb-lg-1">Nossos Serviços</h2>
                        <div className="row mb-8">
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash card-img-top">
                                        <img src={estores} alt="Property Management" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">Estores</h4>
                                        <p className="card-text">
                                            Os estores são uma parte integrante de uma habitação ou de um escritório, assumindo-se como elementos estéticos e de conforto essenciais, ao mesmo tempo que proporcionam um isolamento térmico e acústico e grande privacidade aos espaços interiores, entre outras funções importantes que desempenham.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash card-img-top">
                                        <img src={cortinasdevidro} alt="Mortgage Service" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">Cortinas de Vidro</h4>
                                        <p className="card-text">
                                            Para instalar cortinas de vidro na varanda ou no terraço da sua casa ou do seu apartamento, fale connosco. Os equipamentos que produzimos assentam bem em qualquer ambiente, funcionando como painéis de vidro totalmente transparentes que permitem a entrada de luz natural, sem qualquer barreira visual para o exterior.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash card-img-top">
                                        <img src={janelasaluminiopvc} alt="Consulting Service" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">Janelas PVC</h4>
                                        <p className="card-text">
                                            Fazemos a montagem e também a substituição de janelas em PVC, as quais podem ser de correr, oscilo-batentes ou de batente, e totalmente personalizadas pelos clientes ao nível de cores, dimensões e tipos de vidro, de modo a potenciar a sua segurança e conforto em termos térmicos e acústicos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash card-img-top">
                                        <img src={portaandjanelacaixilharia} alt="Recover Asset Value" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">Caixilharia para portas e janelas</h4>
                                        <p className="card-text">
                                            Na BS Caixilharia vai encontrar uma equipa de especialistas no fabrico, venda, instalação e reparação de caixilharia para portas e janelas ou em PVC, que oferece níveis elevados de isolamento térmico e acústico, graças às propriedades ultravedantes,  influenciando assim a eficiência energética da sua casa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash card-img-top">
                                        <img src={caixilhariaaluminioepvcmarquises} alt="Home Buying" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">
                                            Caixilharia PVC para marquises
                                        </h4>
                                        <p className="card-text">
                                            A nossa empresa executa trabalhos de caixilharia em PVC para marquises de casas, prédios ou de espaços comerciais, os quais são feitos por medida. Estes equipamentos estão disponíveis em vários modelos, cores, feitios e vidros (simples ou térmicos): consulte os nossos catálogos para conhecer a nossa oferta.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-6">
                                <div className="card border-0">
                                    <div className="hover-flash">
                                        <img src={portasaluminios} className="card-img-top" alt="Portas de PVC" />
                                    </div>
                                    <div className="card-body px-0 pt-2 pb-0">
                                        <h4 className="card-title fs-18 lh-17 text-dark mb-0">Portas de PVC</h4>
                                        <p className="card-text">
                                            Na nossa empresa, produzimos e montamos portas em PVC totalmente personalizáveis de acordo com a estrutura do imóvel, necessidades e gostos de cada cliente. Fale connosco para escolher o formato, tipo de abertura, acessórios e cor das suas portas, e ainda para obter um orçamento gratuito e sem compromisso.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}