import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import singlepropertylg9 from '../../img/windowspvc/single-property-lg-9.webp';
import singlepropertylg2 from '../../img/windowspvc/single-property-lg-2.webp';
import singlepropertylg3 from '../../img/windowspvc/single-property-lg-3.webp';
import singlepropertylg17 from '../../img/windowspvc/single-property-lg-17.webp';

import singleproperty7 from '../../img/windowspvc/single-property-7.webp';
import singleproperty9 from '../../img/windowspvc/single-property-9.webp';

import singlepropertysm4 from '../../img/windowspvc/single-property-sm-4.webp';
import singlepropertysm17 from '../../img/windowspvc/single-property-sm-17.webp';
import singlepropertysm1 from '../../img/windowspvc/single-property-sm-1.webp';
import singlepropertysm5 from '../../img/windowspvc/single-property-sm-5.webp';

import window1 from '../../img/windowspvc/window1.webp';
import window2 from '../../img/windowspvc/window2.webp';
import window3 from '../../img/windowspvc/window3.webp';
import window4 from '../../img/windowspvc/window4.webp';

export function WindowsPVC() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_r4ab6qd', 'template_0hr844j', form.current, 'user_w06n8dXFy9FVNFRTD6mju')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <div>
            <section className="pb-7 shadow-5">
                <div className="container">
                    <div className="row galleries">
                        <div className="col-lg-8 mb-6 mb-lg-0">
                            <div className="item custom-size-1">
                                <div className="card p-0 hover-zoom-in">
                                    <a href={singlepropertylg9} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${singleproperty9})` }}>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row no-gutters m-n1 pb-7">
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={singlepropertylg3} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${singlepropertysm4})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={singlepropertylg2} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${singlepropertysm1})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={singlepropertylg17} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${singlepropertysm17})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={singleproperty7} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${singlepropertysm5})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={window1} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${window1})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={window2} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${window2})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={window3} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${window3})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <div className="item item-size-1-1">
                                        <div className="card shadow-hover-xs-3 bg-hover-white bg-transparent p-1">
                                            <a href={window4} className="card-img" data-gtf-mfp="true" data-gallery-id="06" style={{ backgroundImage: `url(${window4})` }}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="primary-content bg-gray-01 pt-7 pb-13">
                <div className="container">
                    <div className="row">
                        <article className="col-lg-8">
                            <section className="pb-8 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-3">Caixilharia em PVC</h4>
                                <p className="mb-0 lh-214">
                                    A montagem e a reparação de janelas em PVC são algumas das atividades principais da
                                    BS Caixilharia. Contacte-nos, se pretende instalar na sua residência ou emprego equipamentos
                                    de elevada qualidade e personalizados em termos de tamanho, formato, cores, sistemas de abertura,
                                    acessórios e tipos de vidro (simples ou duplos).
                                    <p />
                                    <p className="mb-1 lh-214"></p>
                                    Para cada fase de trabalho, desde a produção à assistência pós-venda, contamos com mão de
                                    obra especializada, e profissionais que cumprem prazos rigorosos de fabrico e entrega dos
                                    equipamentos em PVC, os quais têm a vantagem de regular a entrada do calor e da luz natural,
                                    oferecendo a todos os espaços um isolamento térmico e acústico perfeito.
                                    <p />
                                    <p className="mb-1 lh-214"></p>
                                    Como trabalhamos diretamente com os melhores fabricantes de PVC do mercado atual,
                                    conseguimos apresentar aos clientes janelas anti roubo e anti chamas, altamente
                                    resistentes a quaisquer impactos ou choques, de grande durabilidade, robustez, leveza e de fácil
                                    manutenção e aplicação.
                                    <p />
                                    <p className="mb-1 lh-214"></p>
                                    Consulte a nossa equipa técnica para o ajudar a escolher o modelo de janelas em PVC que melhor se
                                    adapta ao seu estilo, e peça-nos um orçamento grátis e sem taxas associadas. Temos flexibilidade
                                    total para o ajudar a encontrar o tipo e o sistema de abertura de janela para satisfazer as suas
                                    necessidades.
                                </p>
                            </section>

                            <section className="mt-2 pb-7 px-6 pt-5 bg-white rounded-lg">
                                <h4 className="fs-22 text-heading mb-4">Vantagens</h4>
                                <ul className="list-unstyled mb-0 row no-gutters">
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Elevada Durabilidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Instalação profissional
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Garantia de qualidade
                                    </li>
                                    <li className="col-sm-6 col-6 mb-2"><i className="far fa-check mr-2 text-primary" />
                                        Melhor relação preço / qualidade
                                    </li>
                                </ul>
                            </section>

                        </article>
                        <aside className="col-lg-4 pl-xl-4 primary-sidebar sidebar-sticky" id="sidebar">
                            <div className="primary-sidebar-inner">
                                <div className="card mb-4">
                                    <div className="card-body px-6 py-4 text-center">
                                        <a href="agent-details-1.html" className="fs-16 lh-214 text-dark mb-0 font-weight-500 hover-primary">
                                            BS Caixilharia
                                        </a>
                                        <p className="mb-0">Comercial</p>
                                        <a href="mailto:bscaixilharia@gmail.com" className="text-body">bscaixilharia@gmail.com</a>
                                        <a href="tel:+351932727803" className="text-heading font-weight-600 d-block mb-4">
                                            (+351) 932 727 803
                                        </a>
                                        <form ref={form} onSubmit={sendEmailForm}>
                                            <div className="form-group mb-2">
                                                <label htmlFor="name" className="sr-only">Full Name</label>
                                                <input type="text" placeholder="Full Name" className="form-control form-control-lg border-0 shadow-none"
                                                    name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="email" className="sr-only">Email</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="email" placeholder="Your email"
                                                    name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="phone" className="sr-only">Phone</label>
                                                <input type="text" className="form-control form-control-lg border-0 shadow-none" id="phone" placeholder="Your phone"
                                                    name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="message" className="sr-only">Message</label>
                                                <textarea className="form-control border-0 shadow-none" id="message" defaultValue={""}
                                                    name="message" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-lg btn-block shadow-none">Solicitar Contacto
                                            </button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>

    );
}