import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function FormContact() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_r4ab6qd', 'template_0hr844j', form.current, 'user_w06n8dXFy9FVNFRTD6mju')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <section>
            <div className="container pt-4">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="border-bottom pb-7">

                            <h2 className="text-heading mb-2 fs-22 fs-md-32 text-center lh-16">
                                Estamos ansiosos para conhecer o seu projeto!
                            </h2>
                            <p className="text-center mxw-670 mb-8">
                                Contacte-nos para passar mais informações e esclarecer sobre o seu projeto.
                            </p>
                            <form ref={form} onSubmit={sendEmailForm}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" placeholder="Full Name" className="form-control form-control-lg border-0"
                                                name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input placeholder="Your Email" className="form-control form-control-lg border-0" type="email"
                                                name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" placeholder="Your Phone" className="form-control form-control-lg border-0"
                                                name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-6">
                                    <textarea className="form-control border-0" placeholder="Message" name="message" rows={5} defaultValue={""}
                                        value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-lg btn-primary px-9">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="pt-1">
                            <div className="row mt-2">
                                <div className="col-md-4 mb-4">
                                    <div className="media">
                                        <span className="fs-32 text-primary mr-4"><i className="fal fa-phone" /></span>
                                        <div className="media-body mt-3">
                                            <h4 className="fs-16 lh-2 mb-1 text-dark">Contato</h4>
                                            <div className="row mb-1">
                                                <div className="col-3">
                                                    <span>Phone</span>
                                                </div>
                                                <div className="col-9">
                                                    <a href="tel:+351932727803" className="text-heading font-weight-500">
                                                        (+351) 932 727 803
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-3">
                                                    <span>E-mail</span>
                                                </div>
                                                <div className="col-9">
                                                    <a href="mailto:bscaixilharia@gmail.com" className="text-body">
                                                        bscaixilharia@gmail.com
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="media">
                                        <span className="fs-32 text-primary mr-4"><i className="fal fa-clock" /></span>
                                        <div className="media-body mt-3">
                                            <h4 className="fs-16 lh-2 mb-1 text-dark">Horário de Operação</h4>
                                            <div className="row mb-1">
                                                <div className="col-6">
                                                    <span>Segunda - Sexta:</span>
                                                </div>
                                                <div className="col-6">
                                                    <span>
                                                        09:00 - 17:00
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-6">
                                                    <span>Sábado e Domingo:</span>
                                                </div>
                                                <div className="col-6">
                                                    <span>
                                                        Encerrado
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-6">
                                                    <span>Almoço:</span>
                                                </div>
                                                <div className="col-6">
                                                    <span>
                                                        12:00 - 13:00
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="media">
                                        <a href="https://goo.gl/maps/kejuLZaFW9kn6QMw6" target="_blank">
                                            <span className="fs-32 text-primary mr-4">
                                                <i className="fal fa-map-marker-alt" />
                                            </span>
                                        </a>
                                        <div className="media-body mt-3">
                                            <h4 className="fs-16 lh-2 mb-1 text-dark">Endereço</h4>
                                            <div className="row mb-1">
                                                <div className="col-12">
                                                    <span>
                                                        Rua Dr. Albano de Sá Lima, 180
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-12">
                                                    <span>
                                                        4450-601 Leça da Palmeira
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <iframe className="mapbox-gl map-point-animate" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2999.893024242471!2d-8.629810684641617!3d41.245888079277464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d913faed5f8de5%3A0xa1993e0cdf13d082!2sBS%20Caixilharia!5e0!3m2!1spt-PT!2spt!4v1644403433637!5m2!1spt-PT!2spt" width="100%" height="450" style={{ border: 0 }} loading="lazy">
                            </iframe>
                        </section>

                    </div>
                </div>
            </div>
        </section>

    );
}