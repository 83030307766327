import { Link } from 'react-router-dom';
import bgslides1 from '../../img/slide/bg-slider-01.webp';
import bgslides2 from '../../img/slide/bg-slider-02.webp';
import windowspvc from '../../img/slide/windowspvc.webp';
import singleproperty2 from '../../img/slide/single-property-lg-2.webp';
import soldaaluminio from '../../img/slide/solda-aluminio.webp';
import estores from '../../img/windowspvc/estores.webp';

export function Slide() {
    return (
        <section>
            <div className="slick-slider mx-0 custom-arrow-center" data-slick-options="{'slidesToShow': 1, 'autoplay':true,'responsive':[{'breakpoint': 1200,'settings': {'slidesToShow':1,'arrows':false,'dots':false}},{'breakpoint': 992,'settings': {'slidesToShow':1,'arrows':false,'dots':false}},{'breakpoint': 768,'settings': {'slidesToShow': 1,'arrows':false,'dots':false}},{'breakpoint': 576,'settings': {'slidesToShow': 1,'arrows':false,'dots':false}}]}">
                <div className="box px-0 d-flex flex-column">
                    <div className="bg-cover d-flex align-items-center custom-vh-02" style={{ backgroundImage: `url(${bgslides1})` }}>
                        <div className="container">
                            <div className="row py-8" data-animate="zoomIn">
                                <div className="col-lg-5 col-md-6 d-md-block d-none">
                                    <Link to="/windows-pvc" className="d-inline-block">
                                        <img src={windowspvc} className="rounded-lg" alt="Villa on Hollywood Boulevard" />
                                    </Link>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                    <p className="text-white fs-22 font-weight-500 letter-spacing-367 text-uppercase mt-7 mb-4">
                                        Serviços
                                    </p>
                                    <h2 className="lh-12 mb-4">
                                        <Link to="/windows-pvc" className="text-white fs-md-60 fs-48">
                                            Janelas em <br /> PVC
                                        </Link>
                                    </h2>
                                    <p className="text-white fs-22 font-weight-500">Descubra as janelas mais bonitas</p>
                                    <Link to="/windows-pvc" className="btn btn-lg btn-primary font-weight-600 rounded-lg mb-8 zoomIn animated" data-animate="zoomIn">
                                        More<i className="far fa-long-arrow-right ml-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="box px-0 d-flex flex-column">
                    <div className="bg-cover d-flex align-items-center custom-vh-02" style={{ backgroundImage: `url(${bgslides2})` }}>
                        <div className="container">
                            <div className="row py-8" data-animate="zoomIn">
                                <div className="col-lg-5 col-md-6 d-md-block d-none">
                                    <a href="single-property-1.html" className="d-inline-block"><img src={soldaaluminio} className="rounded-lg" alt="Villa on Hollywood Boulevard" /></a>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                    <p className="text-white fs-22 font-weight-500 letter-spacing-367 text-uppercase mt-7 mb-4">
                                        Serviços
                                    </p>
                                    <h2 className="lh-12 mb-4"><Link to="/aluminio" className="text-white fs-md-60 fs-48">Alumínios</Link></h2>
                                    <p className="text-white fs-22 font-weight-500">Janelas e acessórios de alumínio</p>
                                    <Link to="/aluminio" className="btn btn-lg btn-primary font-weight-600 rounded-lg mb-8 zoomIn animated" data-animate="zoomIn">
                                        More<i className="far fa-long-arrow-right ml-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="box px-0 d-flex flex-column">
                    <div className="bg-cover d-flex align-items-center custom-vh-02" style={{ backgroundImage: `url(${singleproperty2})` }}>
                        <div className="container">
                            <div className="row py-8" data-animate="zoomIn">
                                <div className="col-lg-5 col-md-6 d-md-block d-none">
                                    <a href="single-property-1.html" className="d-inline-block"><img style={{ width: "70%" }} src={estores} className="rounded-lg" alt="Villa on Hollywood Boulevard" /></a>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                    <p className="text-white fs-22 font-weight-500 letter-spacing-367 text-uppercase mt-7 mb-4">
                                        Serviços
                                    </p>
                                    <h2 className="lh-12 mb-4"><Link to="/estores" className="text-white fs-md-60 fs-48">Estores</Link></h2>
                                    <p className="text-white fs-22 font-weight-500">Abra os estores para um lar saudável</p>
                                    <Link to="/estores" className="btn btn-lg btn-primary font-weight-600 rounded-lg mb-8 zoomIn animated" data-animate="zoomIn">
                                        More<i className="far fa-long-arrow-right ml-2"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}