import { Link } from 'react-router-dom';
import singleImage4 from '../../img/slide/single-image-04.webp';

export function InformationContact() {
    return (
        <section className="bg-single-image-02 bg-accent py-lg-13 py-11" style={{ background: `url(${singleImage4})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-ld-6 col-sm-7" data-animate="fadeInLeft">
                        <div className="pl-6 border-4x border-left border-primary">
                            <h2 className="text-heading lh-15 fs-md-32 fs-25">
                                Para mais informações sobre nossos serviços, <span className="text-primary">entre em contato</span> com nossos consultores especializados.
                            </h2>
                            <p className="lh-2 fs-md-15 mb-0">
                                Estamos ansiosos para conhecer o seu projeto!
                            </p>
                        </div>
                    </div>
                    <div className="col-ld-6 col-sm-5 text-center mt-sm-0 mt-8" data-animate="fadeInRight">
                        <i className="fal fa-phone fs-40 text-primary" />
                        <p className="fs-13 font-weight-500 letter-spacing-173 text-uppercase lh-2 mt-3">
                            Entre em contato agora!
                        </p>
                        <p className="fs-md-42 fs-32 font-weight-600 text-secondary lh-1">(+351) 932 727 803</p>
                        <Link to="/contact" className="btn btn-lg btn-primary mt-2 px-10">Contacte-nos</Link>
                    </div>
                </div>
            </div>
        </section>

    );
}