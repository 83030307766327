import { Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { AboutUs } from './components/main/AboutUs';
import { Aluminio } from './components/main/Aluminio';
import { Breadcrumb } from './components/main/Breadcrumb';
import { CommentsGoogle } from './components/main/CommentsGoogle';
import { Error404 } from './components/main/Error404';
import { Estores } from './components/main/Estores';
import { FormContact } from './components/main/FormContact';
import { InformationContact } from './components/main/InformationContact';
import { PropertyAmenities } from './components/main/PropertyAmenities';
import { Services } from './components/main/Services';
import { Slide } from './components/main/Slide';
import { WindowsPVC } from './components/main/WindowsPVC';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsConditions } from './components/TermsConditions';

function App() {
  return (
    <Routes>

      <Route path="/" element={
        <div>
          <Header />
          <main id="content">
            <Slide />
            <Services />
            <PropertyAmenities />
            <CommentsGoogle />
            <InformationContact />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/contact" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Contato" />
            <FormContact />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/aboutus" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Sobre Nós" />
            <AboutUs />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/services" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Serviços" />
            <Services />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/windows-pvc" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Janelas em PVC" />
            <WindowsPVC />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/aluminio" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Aluminio" />
            <Aluminio />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/estores" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Estores" />
            <Estores />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/terms-conditions" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Terms & Conditions" />
            <TermsConditions />
          </main>
          <Footer />
        </div>
      } />

      <Route path="/privacy-policy" element={
        <div>
          <Header />
          <main id="content">
            <Breadcrumb namePage="Privacy & Policy" />
            <PrivacyPolicy />
          </main>
          <Footer />
        </div>
      } />


      {/* Use it in this way, and it should work: */}
      <Route path='*' element={
        <div>
          <Header />
          <main id="content">
            <Error404 />
          </main>
          <Footer />
        </div>
      } />
    </Routes>
  );
}

export default App;
