import { Link } from 'react-router-dom';

import Site from "../img/Site.webp";
import SiteNegativo from "../img/SiteNegativo.webp";
import LanguagePt from "../img/LanguagePt.webp";
import Whatsapp from "../img/whatsapp.webp";

export function Header() {
    return (
        <header className="main-header navbar-light header-sticky header-sticky-smart header-mobile-lg">
            <div className="sticky-area">
                <div className="container">
                    <nav className="navbar navbar-expand-lg px-0">
                        <a className="navbar-brand" href="/">
                            <img src={Site} alt="BSCaixilharia" className="d-none d-lg-inline-block" />
                            <img src={SiteNegativo} alt="BScaixilharia" className="d-inline-block d-lg-none" />
                        </a>
                        <div className="d-flex d-lg-none ml-auto">
                            <a className="mr-4 position-relative text-white p-2" href="#">
                                <img src={LanguagePt} alt="Language Portugal" />
                            </a>
                            <button className="navbar-toggler border-0 px-0" type="button" data-toggle="collapse" data-target="#primaryMenu01" aria-controls="primaryMenu01" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="text-white fs-24"><i className="fal fa-bars" /></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse mt-3 mt-lg-0 mx-auto flex-grow-0" id="primaryMenu01">
                            <ul className="navbar-nav hover-menu main-menu px-0 mx-lg-n4">
                                <li id="navbar-item-home" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <a className="nav-link p-0 active" href="/">
                                        Home
                                    </a>
                                </li>
                                <li id="navbar-item-about" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <Link className="nav-link p-0 active" to="/aboutus">
                                        Sobre Nós
                                    </Link>
                                </li>
                                <li id="navbar-item-windowpvc" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <Link className="nav-link p-0 active" to="/windows-pvc">
                                        Janelas em PVC
                                    </Link>
                                </li>
                                {/* <li id="navbar-item-aluminio" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <Link className="nav-link p-0 active" to="/aluminio">
                                        Alumínios
                                    </Link>
                                </li> */}
                                <li id="navbar-item-estores" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <Link className="nav-link p-0 active" to="/estores">
                                        Estores
                                    </Link>
                                </li>
                                <li id="navbar-item-contact" aria-haspopup="true" aria-expanded="false" className="nav-item py-2 py-lg-5 px-0 px-lg-4">
                                    <Link className="nav-link p-0 active" to="/contact">
                                        Contato
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-none d-lg-block">
                            <ul className="navbar-nav flex-row justify-content-lg-end d-flex flex-wrap text-body py-2">
                                <li className="nav-item">
                                    <a className="nav-link px-2 position-relative" href="#">
                                        <img src={LanguagePt} alt="Language Portugal" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}